<template lang="pug">
  #logged-in-layout.fill-height
    v-navigation-drawer(
      height="-webkit-fill-available"
      app
      v-model="drawer"
      :temporary="$vuetify.breakpoint.smAndDown"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :expand-on-hover="$vuetify.breakpoint.mdOnly"
      color="blue-grey darken-3"
      dark
      mobile-breakpoint="sm"
    )
      v-sheet(
        color="blue-grey darken-2"
      )
        router-link(
          :to="{ name: 'home' }"
        )
          v-img(
            contain
            :height="$vuetify.breakpoint.mdAndUp ? '116px' : '60px'"
            src="@/assets/ASP_logo_no_text.png"
          )
      v-list
        v-list-item.px-2(
          link
          :to="{ name: 'my-profile' }"
        )
          v-list-item-action
            v-avatar(
              color="red"
              size="36"
            )
              span.white--text.text-uppercase {{ user.initials }}
          v-list-item-content
            v-list-item-title {{ user.firstName }}
      v-divider
      v-list(
        v-if="user.isShopAdmin || user.isAdmin || user.isRegionAdmin"
      )
        v-subheader(
          v-if="!user.isAdmin"
        ) Management
        v-list-item(
          link
          :to="{ name: 'shop-users', params: { shopId: user.shop_id } }"
          @click.stop='drawer = !drawer'
          v-if="user.isShopAdmin"
        )
          v-list-item-action
            v-icon mdi-account-group
          v-list-item-content
            v-list-item-title Users
        v-list-item(
          link
          :to="{ name: 'users', params: { userId: user.id } }"
          @click.stop='drawer = !drawer'
          v-if="user.isAdmin"
        )
          v-list-item-action
            v-icon mdi-account-group
          v-list-item-content
            v-list-item-title Users
        v-list-item(
          link
          :to="{ name: 'shop-techs', params: { shopId: user.shop_id } }"
          @click.stop='drawer = !drawer'
          v-if="user.isShopAdmin"
        )
          v-list-item-action
            v-icon mdi-wrench
          v-list-item-content
            v-list-item-title Techs
        v-list-item(
          link
          :to="{ name: 'shops' }"
          @click.stop='drawer = !drawer'
          v-if="user.isAdmin || user.isRegionAdmin"
        )
          v-list-item-action
            v-icon mdi-office-building-outline
          v-list-item-content
            v-list-item-title Shops
        v-list-item(
          link
          :to="{ name: 'regions' }"
          @click.stop='drawer = !drawer'
          v-if="user.isAdmin"
        )
          v-list-item-action
            v-icon mdi-map-outline
          v-list-item-content
            v-list-item-title Regions
      v-divider(
        v-if="user.isShopAdmin || user.isAdmin"
      )
      v-list
        v-subheader(
          v-if="!user.isAdmin"
        ) Cost Recovery
        v-list-item(
          link
          :to="{ name: 'shop-invoices', params: { shopId: user.shop_id } }"
          @click.stop='drawer = !drawer'
          v-if="user.isShop && user.shop.usesInvoicing"
        )
          v-list-item-action
            v-icon mdi-receipt
          v-list-item-content
            v-list-item-title Invoices
        v-list-item(
          link
          :to="{ name: 'shop-kits', params: { shopId: user.shop_id } }"
          @click.stop='drawer = !drawer'
          v-if="user.isShop"
        )
          v-list-item-action
            v-icon mdi-widgets
          v-list-item-content
            v-list-item-title Kits
        v-list-item(
          link
          :to="{ name: 'shop-products', params: { shopId: user.shop_id } }"
          @click.stop='drawer = !drawer'
          v-if="user.isShop"
        )
          v-list-item-action
            v-icon mdi-package
          v-list-item-content
            v-list-item-title Products
        v-list-item(
          link
          :to="{ name: 'global-products' }"
          @click.stop='drawer = !drawer'
        )
          v-list-item-action
            v-icon mdi-earth
          v-list-item-content
            v-list-item-title Global Products
        v-list-item(
          link
          :to="{ name: 'orders' }"
          @click.stop='drawer = !drawer'
          v-if="user.isAdmin"
        )
          v-list-item-action
            v-icon mdi-format-list-bulleted
          v-list-item-content
            v-list-item-title Orders
      v-divider
      v-list
        v-subheader(
          v-if="!user.isAdmin"
          ) Ordering
        v-list-item(
          link
          :to="{ name: 'shop-orders', params: { shopId: user.shop_id } }"
          @click.stop='drawer = !drawer'
          v-if="user.isShop && user.shop.usesOrdering"
        )
          v-list-item-action
            v-icon mdi-format-list-bulleted
          v-list-item-content
            v-list-item-title Orders

        //- v-list-item(
        //-   link
        //-   :to="{ name: 'techs' }"
        //-   @click.stop='drawer = !drawer'
        //-   v-if="user.isRegionAdmin"
        //- )
        //-   v-list-item-action
        //-     v-icon mdi-tools
        //-   v-list-item-content
        //-     v-list-item-title Techs

      layout.mt-auto.px-4
        v-divider(
          v-if="!user.isAdmin"
        )
        v-list(
          v-if="!user.isAdmin"
        )
          v-subheader.pa-0(
            v-if="!user.isAdmin"
          ) Contact Us
          p.text-caption.my-0.white--text (512)259-8044
          p.text-caption.my-0.white--text customerservice@aspclips.com
          .mt-2
          a.text-caption.my-0.white--text.text-decoration-none(
            :href="`/pdf/AcceptableUsePolicy.pdf`"
            target="_blank"
          ) Acceptable Use Policy
          br
          a.text-caption.my-0.white--text.text-decoration-none(
            :href="`/pdf/PrivacyPolicy.pdf`"
            target="_blank"
          ) Privacy Policy
          br
          a.text-caption.my-0.white--text.text-decoration-none(
            :href="`/pdf/TermsAndConditions.pdf`"
            target="_blank"
          ) Terms and Conditions
      v-divider(
        v-if="!user.isAdmin"
      )
      v-list-item(
        link
        @click.stop='drawer = !drawer'
        v-if="user.isShop && user.shop.usesOrdering"
        href='https://products.aspclips.com', target='_blank'
      )
        v-list-item-action
          v-icon mdi-store
        v-list-item-content
          v-list-item-title ASP Catalog

      .mt-auto.pa-4
        v-btn(
          outlined
          block
          @click="logout"
        )
          v-icon(left) mdi-logout
          | Logout

    v-app-bar(
      v-if="$vuetify.breakpoint.mdAndDown"
      color='white'
      elevation="0"
    )
      | {{ $route.meta.title }}
      v-spacer
      v-app-bar-nav-icon(
        v-if="$vuetify.breakpoint.smAndDown"
        @click.stop='drawer = !drawer'
      )
    v-main.mt-14
      router-view

</template>

<script>

export default {
  name: 'LoggedInLayout',
  data: () => ({
    drawer: false,
    isNewApplicationModalOpen: false
  }),
  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },
  methods: {
    async logout () {
      await this.$store.dispatch('auth/logout')
      window.location.href = '/login'
    }
  }
}
</script>

<style lang="scss" scoped>
#logged-in-layout {
 background-color: hsl(0, 0%, 94%);
}

::v-deep {
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
  }

  .v-list-item__title {
    font-family: 'Hemi';
  }
}
.v-responsive .v-image__image--cover {
  background-size: contain !important;
}
.v-subheader {
  height: 16px;
  margin-bottom: 16px;
}
</style>
